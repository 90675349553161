import { navigate } from 'gatsby-link';
import { FC, useEffect } from 'react';

type PageContext = {
  link: string;
};
type Props = {
  pageContext: PageContext;
};
const Redirect: FC<Props> = ({ pageContext: { link } }) => {
  useEffect(() => {
    navigate(link, { replace: true });
  }, []);
  return null;
};

export default Redirect;
